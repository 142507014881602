<template>
  <b-card>

    <div class="mb-1">
      <!-- input filter -->
      <b-row>
        <!-- CUSTOMER -->
        <b-col md="4">
          <label class="mr-1 text-lg" for="customRadioInline1">Clientes
          </label>

          <v-select v-model="filterSelect.selectCustomer" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="customerOptions" label="name" :reduce="(customer) => customer.customerId"
            placeholder="Seleccione un cliente" value="">
            <template #option="{ name }">
              <feather-icon icon="UserIcon" size="16" class="align-middle mr-50" />
              <span> {{ name }}</span>
            </template>
          </v-select>
        </b-col>

        <!-- PRODUCTS -->
        <b-col md="4">
          <b-form-group :label="$t('labels.products')">
            <v-select v-model="filterSelect.selectProduct" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productsOptions" label="name" :reduce="(product) => product.productId" placeholder="All" />
          </b-form-group>
        </b-col>

        <!-- BUSCADOR -->
        <b-col md="4">
          <b-form-group label="Buscador">
            <b-form-input v-model="filterSelect.q" class="d-inline-block mr-1" :placeholder="$t('labels.search')" />
          </b-form-group>
        </b-col>

        <!-- TYPE: CREDIT, DEBIT -->
        <!-- <b-col md="4">
            <b-form-group :label="$t('labels.typeTransaction')">
            <v-select
              v-model="filterSelect.selectType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              placeholder="All"
            />
            </b-form-group>
          </b-col> -->

        <b-col md="4">
          <b-form-group :label="$t('labels.datefrom')" rules="required">
            <flat-pickr v-model="filterSelect.dateFrom" class="form-control" :config="{
              altInput: true,
              altFormat: 'd-m-Y H:i',
              enableTime: true,
              time_24hr: true,
            }" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('labels.dateto')" rules="required">
            <flat-pickr v-model="filterSelect.dateTo" class="form-control" :config="{
              altInput: true,
              altFormat: 'd-m-Y H:i',
              enableTime: true,
              time_24hr: true,
            }" />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="mb-1">
        <b-col class="d-flex align-items-center justify-content-end">
          <b-button :disabled="loadingData" @click="inputSelect()" class="text-nowrap" variant="primary">
            <b-spinner small v-if="loadingData"></b-spinner>
            <feather-icon v-if="!loadingData" icon="SearchIcon" class="mr-50" />
            <span v-if="!loadingData" class="text-nowrap">{{ $t('labels.search') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- table -->
    <b-overlay :show="loadingData" variant="transparent">
      <vue-good-table mode="remote" :columns="columns" :rows="rows" :totalRows="totalRows"
        :pagination-options="{ enabled: true }" :sort-options="{
          enabled: false,

        }" @on-sort-change="onSortChange">
        <template slot="table-row" slot-scope="props">

          <div v-if="props.column.field == 'createdAt'">
            <div>{{ props.row.createdAt | formatDate }}</div>
          </div>

          <div v-else-if="props.column.field == 'status'">
            <b-button class="text-nowrap" :variant="showStatus(props.row.response) == 200 ? 'success' : 'danger'"
              size="sm">
              {{ showStatus(props.row.response) }}
            </b-button>
          </div>

          <div v-else-if="props.column.field == 'headers'">
            <!-- show modal v-b-modal -->
            <div class="flex flex-wrap">
              <b-button class="mr-1" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal="'modalRequest' + props.row._id"
                size="sm" variant="outline-primary">
                Headers
              </b-button>

              <b-button class="mr-1" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal="'modalResponse' + props.row._id"
                size="sm" variant="outline-primary">
                Response
              </b-button>

              <b-button class="mr-1" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal="'modalData' + props.row._id"
                size="sm" variant="outline-primary">
                Data
              </b-button>

            </div>
            <!-- Header -->
            <b-modal size="xl" :id="'modalRequest' + props.row._id" title="PopUp Header" cancel-variant="outline-secondary"
              scrollable>
              <prism language="javascript">
                {{ props.row.headers | pretty }}
              </prism>
            </b-modal>
            <!-- Response -->
            <b-modal size="xl" :id="'modalResponse' + props.row._id" title="PopUp Response"
              cancel-variant="outline-secondary" scrollable>
              <prism language="javascript">
                {{ props.row.response | pretty }}
              </prism>
            </b-modal>

            <!-- data -->
            <b-modal size="xl" :id="'modalData' + props.row._id" title="PopUp data" cancel-variant="outline-secondary"
              scrollable>
              <prism language="javascript">
                {{ props.row.data | pretty }}
              </prism>
            </b-modal>


          </div>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select v-model="serverParams.perPage" :options="pages" class="mx-1" @input="onPerPageChange" />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>

            <!-- pagination -->
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="serverParams.perPage" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="onPageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import reportsStoreModule from "@/store/reports";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { formatDateToMonthShort, formatDate } from '@core/utils/filter'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import moment from "moment";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    Prism,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  // add filters
  filters: {
    formatDate,
    pretty: function (value) {
      return value;
    },
  },
  data() {
    return {
      showFilter: false,
      loadingData: false,
      pages: [20, 40, 60, 100, 200, 500, 1000, 2000],
      columns: [
        { label: "Customer", field: "customerId" },
        { label: "Product", field: "productId" },
        { label: "Status", field: "status" },
        { label: "Route", field: "route" },
        { label: "Time Execute Ms", field: "totalTimeMsExecution", sortable: true },
        { label: "Header", field: "headers" },
        { label: "Created At", field: "createdAt" },
      ],
      rows: [],
      serverParams: {
        page: 1,
        perPage: 20,
      },
      totalRows: 0,
      customerOptions: [],
      productsOptions: [],
      typeOptions: ['CREDIT', 'DEBIT', 'QUERY'],
      filterSelect: {
        dateTo: new Date(),
        dateFrom: new Date(),
        q: "",
        selectCustomer: "",
        selectProduct: "",
        selectType: "",
      },
      timeout: null,
    };
  },
  watch: {
    showFilter(val) {
      if (val) {
        // this.getCustomer();
        // this.getProducts();
      }
    }
  },
  methods: {
    onSortChange(params) {
      console.log(params);
      this.serverParams.sort = `${params[0]?.type == 'asc' ? '' : '-'}${params[0]?.field}`
      this.getLogConnectionsRequest();
    },
    async getLogConnectionsRequest() {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch(
          "reports/getLogConnectionsRequest",
          { ...this.filterSelect, ...this.serverParams }
        );
        this.rows = res.data.data;
        this.totalRows = res.data.total;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching log connections",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    fillZero(number, length) {
      var my_string = '' + number;
      while (my_string.length < length) {
        my_string = '0' + my_string;
      }

      return my_string;
    },
    onPageChange(page) {
      this.serverParams.page = page;
      this.getLogConnectionsRequest();
    },
    onPerPageChange(perPage) {
      this.serverParams.page = 1
      this.serverParams.perPage = perPage;
      this.getLogConnectionsRequest();
    },
    inputSelect() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.serverParams.page = 1
        this.getLogConnectionsRequest();
      }, 500);

    },
    async getCustomer() {
      const res = await this.$store.dispatch("reports/getCustomer");
      this.customerOptions = res.data.customers;
    },
    async getProducts() {
      const res = await this.$store.dispatch("reports/getProducts");
      this.productsOptions = res.data;
    },
    showStatus(headers) {
      return headers ? headers?.status : "";
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule("reports"))
      store.registerModule("reports", reportsStoreModule);
    // this.getLogConnectionsRequest();
    this.getProducts();
    this.getCustomer();
  },
  created() {
    var today = new Date()
    var date = new Date()
    // 1 año
    var dateOffset = (24 * 60 * 60 * 1000) * 365;
    date.setTime(date.getTime() - dateOffset);
    date.get
    // date from 00:00:00
    this.filterSelect.dateFrom = date.getFullYear() + '-' + this.fillZero((parseInt(date.getMonth()) + 1), 2) + '-' + this.fillZero(date.getDate(), 2) + ' ' + this.fillZero(0, 2) + ':' + this.fillZero(0, 2);
    // hour 23:59:59
    this.filterSelect.dateTo = today.getFullYear() + '-' + this.fillZero((parseInt(today.getMonth()) + 1), 2) + '-' + this.fillZero(today.getDate(), 2) + ' ' + this.fillZero(23, 2) + ':' + this.fillZero(59, 2);
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss" >
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

